import { useDisclosure } from '@mantine/hooks';
import { useState } from 'react';
import { ILinkedEmail } from '@/entities/admin-app/email';
import { useAppSelector } from '@hooks/redux/redux';
import { useUpdateInfo } from '@hooks/users/useUpdateUser';
import { isAdmin } from '@/lib/utils/access';
import { ProfileSectionEnum, ProfileUpdateTypeEnum } from '@/types/enums/users';
import { ILinkedEmailsProps } from '@/containers/pages/user/components/sections/linked-emails';

export const useLinkedEmail = (props: ILinkedEmailsProps) => {
  const { listLinkedEmails, sectionFormKey, form, editUser, currentId, isCreateUserPage } = props;
  const [opened, { open, close }] = useDisclosure(false);
  const [
    isOpenedChangePasswordModal,
    { open: openChangePasswordModal, close: closeChangePasswordModal }
  ] = useDisclosure(false);
  const [isOpenedDeleteEmailModal, { open: openDeleteEmailModal, close: closeDeleteEmailModal }] =
    useDisclosure(false);
  const [isOpenedCreateModal, { open: openCreateModal, close: closeCreateModal }] =
    useDisclosure(false);
  const [selectedEmail, setSelectedEmail] = useState<ILinkedEmail | null>(null);

  const currentUser = useAppSelector((state) => state.userInfo.User);
  const { saveUpdate, isLoading, isSuccess } = useUpdateInfo();

  const handleAddEmail = async (email: ILinkedEmail) => {
    form?.insertListItem(`${sectionFormKey}`, { ...email });
    if (editUser && currentId) {
      await saveUpdate({
        Type: ProfileUpdateTypeEnum.Email,
        Data: {
          [ProfileSectionEnum.LinkedEmail]: [...(listLinkedEmails ?? []), email],
          Id: currentId
        }
      });
    }
  };

  const changePassword = (email: ILinkedEmail) => {
    setSelectedEmail(email);
    openChangePasswordModal();
  };

  const deleteEmailItem = (email: ILinkedEmail) => {
    setSelectedEmail(email);
    openDeleteEmailModal();
  };

  const closeDeleteModal = () => {
    setSelectedEmail(null);
    closeDeleteEmailModal();
  };

  const closePasswordModal = () => {
    setSelectedEmail(null);
    closeChangePasswordModal();
  };

  const visibleCreateButton = isCreateUserPage || (isAdmin(currentUser) && editUser);
  const listEmails = listLinkedEmails?.filter((email) => email.Email) || [];

  return {
    closePasswordModal,
    closeDeleteModal,
    deleteEmailItem,
    changePassword,
    handleAddEmail,
    openCreateModal,
    closeCreateModal,
    open,
    opened,
    close,
    isLoading,
    isOpenedDeleteEmailModal,
    isOpenedChangePasswordModal,
    isSuccess,
    selectedEmail,
    isOpenedCreateModal,
    visibleCreateButton,
    listEmails
  };
};
