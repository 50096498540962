import { FC, createContext, useEffect } from 'react';
import { Loader, ComboboxItem, Tooltip } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import useLoadMore from './hook/useLoadMore';
import { ErrorIcon } from './components/errorIcon';
import { useStyles } from './styles';
import { useTranslation } from 'react-i18next';
import { Combobox } from '@mantine/core';
import { useCombobox } from '@mantine/core';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Box } from '@mantine/core';
import { IconChevronDown } from '@tabler/icons';
import { TextInput } from '@mantine/core';

export interface ISelectProps {
  label?: string;
  placeholder: string;
  defaultValue?: number | null;
  value?: number | null;
  form?: UseFormReturnType<any>;
  handleChange?: (query: string | null) => void;
  fieldName: string;
  list: [];
  isCreateUser?: boolean;
  isRoleComponent?: boolean;
  SkipCustomers?: number[];
  className?: string;
  filialName?: string | null;
  customerField: string;
  disabled?: boolean;
}

interface LoadMoreResult {
  list: ComboboxItem[];
  isLoading: boolean;
  isError: boolean;
  isFetching: boolean;
  total: number;
  loadMore: () => void;
}

export const SelectWithPagination: FC<ISelectProps> = ({
  label,
  placeholder,
  defaultValue,
  value,
  form,
  handleChange,
  fieldName,
  isCreateUser,
  isRoleComponent,
  SkipCustomers,
  className,
  filialName,
  customerField,
  disabled
}) => {
  const { list, isLoading, isFetching, isError, total, loadMore }: LoadMoreResult = useLoadMore(
    defaultValue || value || undefined,
    SkipCustomers
  );
  const { t } = useTranslation();
  const { classes, cx } = useStyles();

  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption()
  });

  useEffect(() => {
    if (value) {
      const filialItem = list.find((item) => item.value === value.toString());
      if (filialItem) {
        form?.setFieldValue(customerField, filialItem.label);
      }
    }
  }, [value, list]);

  const options = list.map((item) => (
    <Combobox.Option value={item.value} key={item.label} active={item.value === value?.toString()}>
      {item.label}
    </Combobox.Option>
  ));

  const handleChangeParentFilials = (query: string | null) => {
    if (form) {
      form?.setFieldValue(fieldName, query ? Number(query) : null);
      if (isCreateUser || isRoleComponent) {
        form?.setFieldValue('Quota.Id', null);
        form?.setFieldValue('Quota.Name', '');
      }
    } else {
      handleChange && handleChange(query);
    }
  };

  useEffect(() => {
    if (list.length && value === 0) {
      form?.setFieldValue(fieldName, list[0].value ? Number(list[0].value) : null);
      form?.resetDirty({ Customer: { Id: Number(list[0].value) } });
    }
  }, [list, value]);

  const rightSectionContent = isFetching ? (
    <Loader size="xs" />
  ) : isError ? (
    <Tooltip color="red" label={t('profile.failedToLoadData')}>
      <ErrorIcon />
    </Tooltip>
  ) : list.length > 0 ? (
    <IconChevronDown size="1rem" color="#98A2B3" onClick={() => combobox.toggleDropdown()} />
  ) : null;

  return (
    <SelectContext.Provider value={{ list, total, loadMore }}>
      <Combobox
        store={combobox}
        position="bottom"
        withinPortal={false}
        zIndex={1100}
        onOptionSubmit={(val) => {
          handleChangeParentFilials(val);
          combobox.closeDropdown();
        }}
        data-cy="selectFilial"
        disabled={isLoading}
      >
        <Combobox.Target>
          <TextInput
            disabled={disabled}
            placeholder={list.length ? placeholder : t('noData')}
            readOnly
            value={filialName ?? ''}
            className={cx(className, classes.selectInput)}
            pointer
            required
            rightSection={rightSectionContent}
            onClick={() => combobox.toggleDropdown()}
            label={label ? label : t('profile.quota')}
            error={form?.getInputProps(fieldName).error}
          />
        </Combobox.Target>

        <Combobox.Dropdown hidden={!list.length}>
          <Combobox.Options className={classes.options}>
            <InfiniteScroll
              scrollableTarget="scrollableDiv"
              dataLength={list?.length}
              next={loadMore}
              height={list.length <= 5 ? 'auto' : '170px'}
              hasMore={list ? total > list?.length : false}
              loader={
                <>
                  {Boolean(list?.length) && (
                    <Box className={classes.infiniteScrollMoreData}>
                      <Loader size="sm" />
                    </Box>
                  )}
                </>
              }
              className={classes.infiniteScroll}
            >
              {options}
            </InfiniteScroll>
          </Combobox.Options>
        </Combobox.Dropdown>
      </Combobox>
    </SelectContext.Provider>
  );
};

interface SelectContextType {
  list: ComboboxItem[];
  total: number;
  loadMore: () => void;
}
export const SelectContext = createContext<SelectContextType>({
  list: [],
  total: 0,
  loadMore: () => {
    // Default implementation does nothing
  }
});
