import { Table } from '@mantine/core';
import { ExternalLinksSettings, SettingsRule } from '@/services/customerSettingsApiArea/types';
import { useTranslation } from 'react-i18next';
import {
  ActionCell,
  FolderTypeCell,
  PasswordCell,
  PeriodCell,
  RolesCell
} from '@/containers/pages/filials/edit/components/tabs/security/components/share-links/components/RulesTable/components/cells';
import classes from './table.module.css';

interface Props {
  rules: SettingsRule[];
  settings: ExternalLinksSettings | undefined;
  loadSettings: () => Promise<void>;
  deleteRule: (id: number) => Promise<void>;
}

const Rows = (props: Props) => {
  const { rules, settings, loadSettings, deleteRule } = props;

  return (
    <>
      {[...rules]
        .sort((a, b) => a.Id - b.Id)
        .map((rule, index) => (
          <Table.Tr className={classes.row} key={index}>
            <RolesCell rule={rule} />
            <PasswordCell rule={rule} />
            <PeriodCell rule={rule} />
            <FolderTypeCell rule={rule} />
            <ActionCell
              loadSettings={loadSettings}
              deleteRule={deleteRule}
              settings={settings}
              rule={rule}
            />
          </Table.Tr>
        ))}
    </>
  );
};

export const RulesTable = (props: Props) => {
  const { t } = useTranslation();

  return (
    <div className={classes.scrollWrapper}>
      <Table>
        <Table.Thead>
          <Table.Tr>
            <Table.Th w={300} className={classes.cell}>
              {t('ShareLinks.RulesTable.roles')}
            </Table.Th>
            <Table.Th className={classes.cell}>{t('ShareLinks.RulesTable.password')}</Table.Th>
            <Table.Th className={classes.cell}>
              {t('ShareLinks.RulesTable.expirationDate')}
            </Table.Th>
            <Table.Th className={classes.cell}>{t('ShareLinks.RulesTable.folderType')}</Table.Th>
            <Table.Th></Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          <Rows {...props} />
        </Table.Tbody>
      </Table>
    </div>
  );
};
