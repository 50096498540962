import { Table, Tooltip } from '@mantine/core';
import React from 'react';
import { SettingsRule } from '@/services/customerSettingsApiArea/types';
import { useTranslation } from 'react-i18next';
import classes from './cells.module.css';

interface Props {
  rule: SettingsRule;
}

const useGeneratePasswordTooltip = (rule: SettingsRule): string[] => {
  const { t } = useTranslation();

  if (!rule.Password) {
    return [];
  }

  const tooltipLines = [
    `${t('ShareLinks.RulesTable.Cells.Password.required')}: ${rule.Password.MinLength}`,
    t('ShareLinks.RulesTable.Cells.Password.requirements'),
    rule.Password.IsLow ? `- ${t('ShareLinks.RulesTable.Cells.Password.lowercase')}` : null,
    rule.Password.IsHigh ? `- ${t('ShareLinks.RulesTable.Cells.Password.uppercase')}` : null,
    rule.Password.IsDigit ? `- ${t('ShareLinks.RulesTable.Cells.Password.digit')}` : null
  ];

  return tooltipLines.filter(Boolean) as string[];
};

export const PasswordCell = ({ rule }: Props) => {
  const { t } = useTranslation();

  const tooltipContent = useGeneratePasswordTooltip(rule);

  if (tooltipContent.length === 0) {
    return (
      <Table.Td width={100} className={classes.cell}>
        <div>{t('ShareLinks.RulesTable.Cells.Password.optional')}</div>
      </Table.Td>
    );
  }

  return (
    <Table.Td width={100} className={classes.cell}>
      <Tooltip
        multiline
        w={250}
        withArrow
        transitionProps={{ duration: 200 }}
        label={
          <div>
            {tooltipContent.map((line, index) => (
              <div key={index}>{line}</div>
            ))}
          </div>
        }
      >
        <div>
          {rule.Password && t('ShareLinks.RulesTable.Cells.Password.required')}
          {!rule.Password && t('ShareLinks.RulesTable.Cells.Password.optional')}
        </div>
      </Tooltip>
    </Table.Td>
  );
};
