import React, { useCallback } from 'react';
import { IconX } from '@tabler/icons';
import { useRulesSettings } from '@/containers/pages/filials/edit/components/tabs/security/components/share-links/components/RulesSettings/useRulesSettings';
import { ActionIcon, Button, Checkbox, NumberInput, Radio } from '@mantine/core';
import {
  IPillOption,
  PillsInputAutoCompleteWithQuery
} from '@/containers/pages/filials/edit/components/tabs/security/components/share-links/components/PillsInputAutoCompleteWithQuery';
import { roleApiArea } from '@/services/roleApiArea';
import { IRolesTableData } from '@/entities/admin-app/roles';
import { useAppSelector } from '@hooks/redux/redux';
import { IFiltersEnumType } from '@/types/enums';
import { ExternalLinksSettings, SettingsRule } from '@/services/customerSettingsApiArea/types';
import { useTranslation } from 'react-i18next';
import classes from './RulesSettings.module.css';

interface Props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  settings: ExternalLinksSettings | undefined;
  rule?: SettingsRule;

  onSuccess(): Promise<void>;
}

export const RulesSettings = (props: Props) => {
  const { rule, settings, onSuccess } = props;
  const { t } = useTranslation();
  const { filial } = useAppSelector((state) => state.filials);
  const onClose = () => props.setIsOpen(false);

  const [form, isLoading, handleSubmit] = useRulesSettings({
    onClose,
    rule,
    settings,
    onSuccess,
    customerId: filial?.Id
  });

  const loader = useCallback(
    (query: string) => {
      return roleApiArea().GetRolesByName({
        Query: query,
        FilterConditions: filial
          ? [
              {
                Type: IFiltersEnumType.FilialFilter,
                NumValues: [filial.Id]
              }
            ]
          : []
      });
    },
    [filial?.Id]
  );

  const handleValueChange = useCallback((items: IPillOption<IRolesTableData>[]) => {
    form.setFieldValue(
      'roles',
      items.map((item) => item.entity)
    );
  }, []);

  const handleMapDataToOptions = useCallback(
    (item: IRolesTableData) => ({
      label: item.Name,
      value: item.Id,
      entity: item
    }),
    []
  );

  const disabled = Object.keys(form.errors).length > 0 || isLoading;

  const changeExternalLinkRuleDirectory = (value: string) => {
    const current = form.values.folderTypes;
    const currentValue = current.includes(value.toString())
      ? current.filter((item: string) => item !== value.toString())
      : [...current, value.toString()];
    form.setFieldValue('folderTypes', currentValue);

    if (currentValue.length === 0) {
      form.setFieldError('folderTypes', t('ShareLinks.RulesPopUp.folderTypeError'));
    } else {
      form.clearFieldError('folderTypes');
    }
  };

  const title = props.rule
    ? t('ShareLinks.RulesPopUp.editTitle')
    : t('ShareLinks.RulesPopUp.createTitle');
  const submit = props.rule ? t('ShareLinks.RulesPopUp.edit') : t('ShareLinks.RulesPopUp.create');

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <div className="title">{title}</div>
        <ActionIcon onClick={onClose}>
          <IconX size={16} />
        </ActionIcon>
      </div>
      <div className={classes.body}>
        <div className={classes.part}>
          <div className={classes.subTitle}>{t('ShareLinks.RulesPopUp.roles')}</div>
          <PillsInputAutoCompleteWithQuery<IPillOption<IRolesTableData>, IRolesTableData>
            loaderData={loader}
            onValueChange={handleValueChange}
            defaultValues={form.values.roles.map(handleMapDataToOptions)}
            mapDataToOptions={handleMapDataToOptions}
            props={{
              PillsInputFieldProps: {
                placeholder: t('ShareLinks.RulesPopUp.chooseRoles')
              },
              PillsInputProps: {
                error: form.errors.roles ?? null
              }
            }}
          />
        </div>
        <div className={classes.part}>
          <div className={classes.subTitle}>{t('ShareLinks.RulesPopUp.password')}</div>
          <Radio.Group
            value={form.values.passwordRequired ? 'required' : 'optional'}
            onChange={(value) => {
              const currentValue = value === 'required';
              if (!currentValue) {
                form.clearFieldError('minPasswordLength');
              } else {
                form.values.minPasswordLength === 0 &&
                  form.setFieldError('minPasswordLength', 'Поле не может быть пустым');
              }
              form.setFieldValue('passwordRequired', currentValue);
            }}
          >
            <Radio size="xs" mb="sm" value="optional" label={t('ShareLinks.RulesPopUp.optional')} />
            <Radio size="xs" mb="sm" value="required" label={t('ShareLinks.RulesPopUp.required')} />
          </Radio.Group>
          {form.values.passwordRequired && (
            <div className={classes.shift}>
              <NumberInput
                label={t('ShareLinks.RulesPopUp.minPasswordLength')}
                min={6}
                width={250}
                allowNegative={false}
                maxLength={9}
                classNames={{
                  root: classes.inputRoot,
                  input: classes.input,
                  label: classes.label
                }}
                error={form.errors.minPasswordLength || null}
                value={form.values.minPasswordLength}
                onChange={(val) => form.setFieldValue('minPasswordLength', val)}
              />
              <Checkbox
                label={t('ShareLinks.RulesPopUp.includeLowercase')}
                checked={form.values.includeLowercase}
                onChange={(e) => form.setFieldValue('includeLowercase', e.currentTarget.checked)}
              />
              <Checkbox
                label={t('ShareLinks.RulesPopUp.includeUppercase')}
                checked={form.values.includeUppercase}
                onChange={(e) => form.setFieldValue('includeUppercase', e.currentTarget.checked)}
              />
              <Checkbox
                label={t('ShareLinks.RulesPopUp.includeNumber')}
                checked={form.values.includeNumber}
                onChange={(e) => form.setFieldValue('includeNumber', e.currentTarget.checked)}
              />
            </div>
          )}
        </div>
        <div className={classes.part}>
          <div className={classes.subTitle}>{t('ShareLinks.RulesPopUp.expirationDate')}</div>
          <Radio.Group
            withAsterisk
            value={form.values.limitExpiration ? 'limit' : 'unlimited'}
            onChange={(value) => {
              const currentValue = value === 'limit';
              if (!currentValue) {
                form.clearFieldError('expirationTime');
              } else {
                form.values.expirationTime === 0 &&
                  form.setFieldError('expirationTime', 'Поле не может быть пустым');
              }
              form.setFieldValue('limitExpiration', currentValue);
            }}
          >
            <Radio
              size="xs"
              mb="sm"
              value="unlimited"
              label={t('ShareLinks.RulesPopUp.unlimited')}
            />
            <Radio size="xs" mb="sm" value="limit" label={t('ShareLinks.RulesPopUp.limited')} />
          </Radio.Group>
          {form.values.limitExpiration && (
            <div className={classes.shift}>
              <NumberInput
                label={t('ShareLinks.RulesPopUp.expirationTime')}
                error={form.errors.expirationTime || null}
                min={1}
                width={250}
                maxLength={9}
                classNames={{
                  root: classes.inputRoot,
                  input: classes.input,
                  label: classes.label
                }}
                allowNegative={false}
                value={form.values.expirationTime}
                onChange={(val) => form.setFieldValue('expirationTime', val)}
              />
            </div>
          )}
        </div>
        <div className={classes.part}>
          <div className={classes.subTitle}>{t('ShareLinks.RulesPopUp.folderType')}</div>
          <Radio.Group
            withAsterisk
            value={form.values.folderTypeLimited ? 'limited' : 'unlimited'}
            onChange={(value) => {
              const currentValue = value === 'limited';
              if (!currentValue) {
                form.clearFieldError('folderTypes');
              } else {
                form.values.folderTypes.length === 0 &&
                  form.setFieldError('folderTypes', 'Выберите хотя бы один тип папки');
              }
              form.setFieldValue('folderTypeLimited', value === 'limited');
            }}
          >
            <Radio
              size="xs"
              mb="sm"
              value="unlimited"
              label={t('ShareLinks.RulesPopUp.unlimited')}
            />
            <Radio size="xs" mb="sm" value="limited" label={t('ShareLinks.RulesPopUp.limited')} />
          </Radio.Group>
          {form.values.folderTypeLimited && (
            <Checkbox.Group
              className={classes.shift}
              error={form.errors.folderTypes ? t('ShareLinks.RulesPopUp.folderTypeError') : null}
              value={form.values.folderTypes}
            >
              <div className={classes.shift}>
                <Checkbox
                  value="1"
                  readOnly
                  checked={form.values.folderTypes.includes('1')}
                  onClick={() => changeExternalLinkRuleDirectory('1')}
                  label={t('ShareLinks.RulesPopUp.myDocuments')}
                />
                <Checkbox
                  value="4"
                  readOnly
                  checked={form.values.folderTypes.includes('4')}
                  onClick={() => changeExternalLinkRuleDirectory('4')}
                  label={t('ShareLinks.RulesPopUp.commonFolder')}
                />
                <Checkbox
                  value="2"
                  readOnly
                  checked={form.values.folderTypes.includes('2')}
                  onClick={() => changeExternalLinkRuleDirectory('2')}
                  label={t('ShareLinks.RulesPopUp.room')}
                />
              </div>
            </Checkbox.Group>
          )}
        </div>
      </div>
      <div className={classes.footer}>
        <Button
          fullWidth
          size="sm"
          radius={8}
          loading={isLoading}
          disabled={disabled}
          onClick={handleSubmit}
        >
          {submit}
        </Button>
        <Button fullWidth size="sm" color="grey" radius={8} variant="light" onClick={onClose}>
          {t('ShareLinks.RulesPopUp.cancel')}
        </Button>
      </div>
    </div>
  );
};
