import { FC, useEffect } from 'react';
import { Box, Combobox, Flex, Loader, Select, TextInput, Tooltip } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { useStyles } from './styles';
import userLoadQuotas from './useLoadQuotas';
import { ErrorIcon } from '@/containers/pages/filials/components/select/components/errorIcon';
import { useTranslation } from 'react-i18next';
import { useCombobox } from '@mantine/core';
import { CloseButton } from '@mantine/core';
import { IconChevronDown } from '@tabler/icons';
import InfiniteScroll from 'react-infinite-scroll-component';
import { formatDataSizeUnits } from '@/lib/utils/format';

interface IQuotaSizeProps {
  defaultValue?: number | null;
  form?: UseFormReturnType<any>;
  handleChange?: (query: string | null) => void;
  fieldName: string;
  value?: number | null;
  QuotaName?: string | null;
  isLimitedAccess?: boolean;
  editUser?: boolean;
  Customer?: number | null;
  createUser?: boolean;
  notClearable?: boolean;
  label?: string;
  className?: string;
  disabled?: boolean;
  quotaField: string;
}

export const SelectQuotas: FC<IQuotaSizeProps> = ({
  // defaultValue,
  form,
  handleChange,
  fieldName,
  value,
  QuotaName,
  isLimitedAccess,
  Customer,
  createUser,
  notClearable,
  label,
  quotaField,
  className
}) => {
  const { classes, cx } = useStyles();
  const { t } = useTranslation();
  const { list, total, isFetching, isError, defaultQuotaId, loadMore } = userLoadQuotas(
    Customer,
    createUser
  );

  const error = form ? form.errors[`${fieldName}.Id`] : '';

  const handleChangeQuota = (query: string | null) => {
    if (form) {
      form?.setFieldValue(fieldName, query ? Number(query) : null);
    } else {
      handleChange && handleChange(query);
    }
  };

  const clearQuota = () => {
    if (form) {
      form?.setFieldValue(fieldName, null);
      form?.setFieldValue(quotaField, '');
    }
  };

  useEffect(() => {
    if (value) {
      const quotaItem = list.find((item) => item.value === value.toString());
      if (quotaItem) {
        form?.setFieldValue(quotaField, quotaItem.label);
      }
    }
  }, [value, list]);

  useEffect(() => {
    if (defaultQuotaId) {
      form?.setFieldValue(fieldName, Number(defaultQuotaId));
      form?.setFieldValue(quotaField, list.find((item) => item.value === defaultQuotaId)?.label);
    }
  }, [defaultQuotaId]);

  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption()
  });
  const options = list.map((item) => (
    <Combobox.Option value={item.value} key={item.label} active={item.value === value?.toString()}>
      <Flex w="100%" justify="space-between">
        <Box>{item.label}</Box>
        <Box> {formatDataSizeUnits(item?.size)}</Box>
      </Flex>
    </Combobox.Option>
  ));

  const rightSectionContent = isFetching ? (
    <Loader size="xs" />
  ) : isError ? (
    <Tooltip zIndex={1400} color="red" label={t('profile.failedToLoadData')}>
      <ErrorIcon />
    </Tooltip>
  ) : list.length > 0 ? (
    notClearable ? (
      <IconChevronDown size="1rem" color="#98A2B3" onClick={() => combobox.toggleDropdown()} />
    ) : value === null ? (
      <IconChevronDown size="1rem" color="#98A2B3" onClick={() => combobox.toggleDropdown()} />
    ) : (
      <CloseButton
        size="sm"
        onMouseDown={(event) => event.preventDefault()}
        onClick={() => clearQuota()}
      />
    )
  ) : null;

  return (
    <>
      {isLimitedAccess ? (
        <Select
          data={[{ value: value?.toString() || '', label: QuotaName || '' }]}
          value={`${value || ''}`}
          readOnly={true}
          sx={{ width: '100%' }}
          error={error}
          disabled
        />
      ) : (
        <Combobox
          store={combobox}
          position="bottom"
          withinPortal={false}
          zIndex={1400}
          onOptionSubmit={(val) => {
            handleChangeQuota(val);
            combobox.closeDropdown();
          }}
          data-cy="addRoleQuotaSelectt"
        >
          <Combobox.Target>
            <TextInput
              placeholder={
                list.length ? t('profile.selectDefaultQuota') : t('filials.diskSpace.noQuotas')
              }
              readOnly
              value={QuotaName || ''}
              className={cx(classes.selectInput, className)}
              pointer
              rightSection={rightSectionContent}
              onClick={() => combobox.toggleDropdown()}
              label={label ? label : t('profile.quota')}
              error={form?.getInputProps(fieldName).error}
              required={notClearable ? true : false}
            />
          </Combobox.Target>

          <Combobox.Dropdown hidden={!list.length}>
            <Combobox.Options className={classes.options}>
              <InfiniteScroll
                scrollableTarget="scrollableDiv"
                dataLength={list?.length}
                next={loadMore}
                height={list.length <= 5 ? 'auto' : '140px'}
                hasMore={list ? total > list?.length : false}
                loader={
                  <>
                    {Boolean(list?.length) && (
                      <Box className={classes.infiniteScrollMoreData}>
                        <Loader size="sm" />
                      </Box>
                    )}
                  </>
                }
                className={classes.infiniteScroll}
              >
                {options}
              </InfiniteScroll>
            </Combobox.Options>
          </Combobox.Dropdown>
        </Combobox>
      )}
    </>
  );
};
