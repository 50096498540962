import { Table } from '@mantine/core';
import React from 'react';
import { SettingsRule } from '@/services/customerSettingsApiArea/types';
import { useTranslation } from 'react-i18next';
import type { TFunction } from 'i18next';
import classes from './cells.module.css';

interface Props {
  rule: SettingsRule;
}

const formatMaxActivePeriod = (period: number | null, t: TFunction): string => {
  if (period === null) return t('ShareLinks.RulesTable.Cells.Password.optional');
  if (period === 0) return t('ShareLinks.RulesTable.Cells.Password.optional');
  return t('ShareLinks.RulesTable.Cells.Password.requiredCount', { count: period });
};

export const PeriodCell = ({ rule }: Props) => {
  const { t } = useTranslation();
  const formattedPeriod = formatMaxActivePeriod(rule.MaxActivePeriod, t);

  return (
    <Table.Td width={100} className={classes.cell}>
      {formattedPeriod}
    </Table.Td>
  );
};
