import { FC, useEffect } from 'react';
import { Box, Button, NumberInput, SimpleGrid, UnstyledButton } from '@mantine/core';
import { FormErrors, useForm } from '@mantine/form';
import { z } from 'zod';
import { TextFieldInput } from '@components/fields/text-field-input';
import { getValidateErrors } from '@/lib/utils/validation';
import { convertByteToGb, convertGbToByte } from '@/lib/utils/format';
import { useTranslation } from 'react-i18next';
import { useStyles } from '../../../../../styles';
import { IFilialQuota } from '@/entities/admin-app/quotas';
import { useUpdateFilialQuotaMutation } from '@/entities/admin-app/quotas/api';
import { showNotification } from '@mantine/notifications';
import { extractErrorCode } from '@/lib/utils/extractFetchErrors';
import { generateErrorMessage } from '@/lib/utils/generateErrorMessage';

interface ISelectGroupModalProps {
  isOpened: boolean;
  onClose: () => void;
  quota?: IFilialQuota;
  onEditQuota: () => void;
}

interface IQuotaForm {
  name?: string;
  size: number;
}

export const FilialQuotaModal: FC<ISelectGroupModalProps> = ({ onClose, quota, onEditQuota }) => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const [updateFilialQuota] = useUpdateFilialQuotaMutation();
  const form = useForm<IQuotaForm>({
    validateInputOnChange: true,
    validate: (values: IQuotaForm): FormErrors => getValidateErrors(validateScheme, values)
  });

  const validateScheme = z.object({
    size: z
      .number({
        required_error: t('filials.diskSpace.errors.size'),
        invalid_type_error: t('filials.diskSpace.errors.type')
      })
      .max(2097151, { message: t('filials.diskSpace.errors.filialMax') })
      .nonnegative({ message: t('filials.diskSpace.errors.positive') })
      .safe()
  });

  const handleSubmit = async (formValues: IQuotaForm) => {
    try {
      const res = await updateFilialQuota({
        QuotaSize: convertGbToByte(formValues.size),
        CustomerId: quota?.CustomerId
      });

      if ('error' in res) {
        const codeError = extractErrorCode(res.error);
        showNotification({
          title: generateErrorMessage(codeError),
          message: '',
          autoClose: true,
          color: 'red',
          style: { zIndex: 1003 }
        });
      } else {
        showNotification({
          title: t('saved'),
          message: '',
          autoClose: true,
          color: 'green',
          style: { zIndex: 1003 }
        });

        onClose();
        form.reset();
        onEditQuota();
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (quota) {
      const formValue = {
        name: quota?.Name || t('filials.diskSpace.commonQuota'),
        size: quota?.QuotaSize ? convertByteToGb(quota?.QuotaSize) : 0
      };
      form.setValues(formValue);
      form.resetDirty(formValue);
    }
  }, [quota]);

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Box px="md" py="sm">
        <SimpleGrid cols={1}>
          <TextFieldInput
            form={form}
            fieldName="name"
            label={t('filials.diskSpace.quotaName')}
            disabled={true}
          />

          <NumberInput
            radius="8px"
            className={classes.textFieldInput}
            label={t('filials.diskSpace.quotaSizeInGb')}
            allowNegative={false}
            {...form.getInputProps('size')}
          />
        </SimpleGrid>
      </Box>

      <Box className={classes.modalFooter}>
        <Button type="submit" size="sm" radius={8} w="49%" disabled={!form.isValid()}>
          {t('save')}
        </Button>

        <UnstyledButton
          type="button"
          ml={8}
          className={classes.modalCancelBtn}
          w="49%"
          onClick={onClose}
        >
          {t('cancel')}
        </UnstyledButton>
      </Box>
    </form>
  );
};
