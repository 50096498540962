import { Table } from '@mantine/core';
import React from 'react';
import { SettingsRule } from '@/services/customerSettingsApiArea/types';
import { useTranslation } from 'react-i18next';
import type { TFunction } from 'i18next';
import classes from './cells.module.css';

interface Props {
  rule: SettingsRule;
}

const getMapDirectoryToLabel = (dir: number, t: TFunction): string => {
  switch (dir) {
    case 1:
      return t('ShareLinks.RulesTable.Cells.FolderType.myDocuments');
    case 2:
      return t('ShareLinks.RulesTable.Cells.FolderType.sharedFolder');
    case 4:
      return t('ShareLinks.RulesTable.Cells.FolderType.room');
    default:
      return t('ShareLinks.RulesTable.Cells.FolderType.unknownType'); // Локализованная строка для неизвестного значения
  }
};

const externalLinkRuleDirectoryValue = (rule: SettingsRule, t: TFunction): string => {
  if (!rule.ExternalLinkRuleDirectory || rule.ExternalLinkRuleDirectory.length === 0) {
    return t('ShareLinks.RulesTable.Cells.FolderType.notLimited');
  }

  return rule.ExternalLinkRuleDirectory.map((v) => getMapDirectoryToLabel(v, t)).join(', ');
};

export const FolderTypeCell = ({ rule }: Props) => {
  const { t } = useTranslation();
  const value = externalLinkRuleDirectoryValue(rule, t);

  return (
    <Table.Td width={200} className={classes.cell}>
      {value}
    </Table.Td>
  );
};
