import axiosInstance from '@/api';
import {
  CreateCustomerRequest,
  CreateCustomerResponse,
  CustomerSettingsParams,
  GetCustomerParams,
  GetCustomerResponse,
  SaveCustomerSettingsResponse,
  UpdateCustomerRequest,
  UpdateCustomerResponse
} from '@/services/customerSettingsApiArea/types';
import { CustomerSettingsServiceType } from '@/services/customerSettingsApiArea/interface';

export class CustomerSettingsService implements CustomerSettingsServiceType {
  async SaveCustomerSettings(
    params: CustomerSettingsParams
  ): Promise<SaveCustomerSettingsResponse> {
    try {
      const { data } = await axiosInstance.post<SaveCustomerSettingsResponse>(
        'v2/customer/settings',
        params
      );

      return data;
    } catch (e) {
      throw new Error('Unable to save customer settings. Please try again later.');
    }
  }

  async GetCustomer(params?: GetCustomerParams): Promise<GetCustomerResponse> {
    try {
      const { data } = await axiosInstance.get<GetCustomerResponse>('v2/customer', {
        params
      });

      return data;
    } catch (e) {
      throw new Error('Unable to fetch customer data. Please try again later.');
    }
  }

  async CreateCustomer(params: CreateCustomerRequest): Promise<CreateCustomerResponse> {
    try {
      const { data } = await axiosInstance.post<CreateCustomerResponse>('v2/customer', params);

      return data;
    } catch (e) {
      throw new Error('Unable to create customer. Please try again later.');
    }
  }

  async UpdateCustomer(params: UpdateCustomerRequest): Promise<UpdateCustomerResponse> {
    try {
      const { data } = await axiosInstance.put<UpdateCustomerResponse>('v2/customer', params);

      return data;
    } catch (e) {
      throw new Error('Unable to update customer. Please try again later.');
    }
  }
}
