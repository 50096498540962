import React, { FC, useContext, useEffect, useId } from 'react';
import { Input, Loader } from '@mantine/core';
import { useStyles } from '@components/fields/phone/styles';
import InputMask from 'react-input-mask';
import { useLazyCheckUserExistFieldQuery } from '@/entities/admin-app/auth/api';
import { getNameFromDottedString } from '@/containers/pages/user/utils';
import { UseFormReturnType } from '@mantine/form';
import { EditUserInfoContext } from '@/containers/pages';
import { useTranslation } from 'react-i18next';

interface IValidatePhoneProps {
  label?: string;
  mask: string | Array<string | RegExp>;
  className?: string;
  fieldName: string;
  form: UseFormReturnType<any>;
  disabled?: boolean;
}
export const ValidatePhoneInput: FC<IValidatePhoneProps> = ({
  label,
  mask,
  className,
  form,
  fieldName,
  disabled
}) => {
  const { t } = useTranslation();
  const { classes, cx } = useStyles();
  const fieldPhoneId = useId() || '';
  const { userInfoCommon } = useContext(EditUserInfoContext);

  const [checkFieldExist, { isFetching: isFetchingValidate }] = useLazyCheckUserExistFieldQuery();

  const checkPhoneValues =
    userInfoCommon?.Common.Phone == form.getInputProps(fieldName).value.replace(/\D/g, '');

  const handleValidateField = async (field: string | undefined) => {
    if (
      field?.length &&
      !form.getInputProps(fieldName).error &&
      form.isDirty(fieldName) &&
      !checkPhoneValues
    ) {
      const fieldNameEnum = getNameFromDottedString(fieldName);
      const response = await checkFieldExist({
        existField: fieldNameEnum,
        field
      });
      if (response?.data?.ExistsUser) {
        form.setFieldError(fieldName, t('requestsErrors.userExistsByPhone'));
      }
    }
  };

  const rightSectionRender = () => {
    if (isFetchingValidate) {
      return <Loader size="xs" />;
    }
  };

  useEffect(() => {
    if (form.isValid(fieldName)) {
      handleValidateField(form.getInputProps(fieldName).value);
    }
  }, [form.getInputProps(fieldName).error]);

  return (
    <Input.Wrapper
      id={fieldPhoneId}
      // error={error}
      error={form.getInputProps(fieldName).error}
      className={cx(classes.wrapper, className)}
      label={label}
    >
      <Input
        {...form.getInputProps(fieldName)}
        data-cy="phoneInput"
        id={fieldPhoneId}
        component={InputMask}
        mask={mask}
        rightSection={rightSectionRender()}
        error={form.getInputProps(fieldName).error}
        value={form.getInputProps(fieldName).value || ''}
        onChange={form.getInputProps(fieldName).onChange}
        disabled={disabled}
      />
    </Input.Wrapper>
  );
};
