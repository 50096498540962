import { Pill } from '@mantine/core';
import React from 'react';
import { IPillOption } from '@/containers/pages/filials/edit/components/tabs/security/components/share-links/components/PillsInputAutoCompleteWithQuery';

export interface IPillAutoCompletePillComponent<T> {
  input: string;
  item: T;
  values: T[];

  renderLabel(item: T): string;

  handleValueRemove(item: T): void;
}

export const DefaultPill = <T extends IPillOption>(props: IPillAutoCompletePillComponent<T>) => {
  const { item, renderLabel, handleValueRemove } = props;

  return (
    <Pill key={item.value} withRemoveButton onRemove={() => handleValueRemove(item)}>
      {renderLabel ? renderLabel(item) : item.label}
    </Pill>
  );
};
