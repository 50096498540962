import { Checkbox, Combobox, Group } from '@mantine/core';
import React from 'react';
import { IPillOption } from '@/containers/pages/filials/edit/components/tabs/security/components/share-links/components/PillsInputAutoCompleteWithQuery';

export interface IPillAutoCompleteOptionComponent<T> {
  input: string;
  item: T;
  values: T[];

  options: T[];
  setInput: React.Dispatch<React.SetStateAction<string>>;
  setValues: React.Dispatch<React.SetStateAction<T[]>>;
}

export const DefaultOption = <T extends IPillOption>(
  props: IPillAutoCompleteOptionComponent<T>
) => {
  const { item, values } = props;
  const valuesIds = values.map((value) => value.value);

  return (
    <Combobox.Option value={item.value.toString()} key={item.value} active={values.includes(item)}>
      <Group gap="sm">
        <Checkbox readOnly checked={valuesIds.includes(item.value)} size="xs" />
        <span>{item.label}</span>
      </Group>
    </Combobox.Option>
  );
};
