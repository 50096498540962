import { Button, Checkbox, Skeleton } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { useShareLinks } from '@/containers/pages/filials/edit/components/tabs/security/components/share-links/useShareLinks';
import React from 'react';
import { IUsePopUpUI, PopUpUI } from '@components/UI/PopUp';
import { RulesSettings } from '@/containers/pages/filials/edit/components/tabs/security/components/share-links/components/RulesSettings';
import { useAppSelector } from '@hooks/redux/redux';
import { RulesTable } from '@/containers/pages/filials/edit/components/tabs/security/components/share-links/components/RulesTable';
import classes from './style.module.css';
import { createStyles } from '@mantine/emotion';
import { IconPlus } from '@tabler/icons-react';
import { themeColors } from '@/theme/colors';

const useCX = createStyles({});

const RulesSettingsPopUpTrigger = (props: IUsePopUpUI) => {
  const { setIsOpen } = props;
  const { t } = useTranslation();

  const handleOpen = () => setIsOpen(true);

  return (
    <Button
      className={classes.addException}
      classNames={{
        inner: classes.addExceptionInner,
        label: classes.addExceptionLabel
      }}
      size="sm"
      variant="outline"
      rightSection={<IconPlus color={themeColors.brandGray[0]} />}
      onClick={handleOpen}
    >
      {t('ShareLinks.addRule')}
    </Button>
  );
};

export const ShareLinks = () => {
  const { cx } = useCX();
  const { t } = useTranslation();
  const { filial } = useAppSelector((state) => state.filials);

  const [form, handleSubmit, settings, loading, loadSettings, deleteRule] = useShareLinks(
    filial?.Id
  );

  const exceptionCheckbox = cx(classes.checkbox, classes.exceptionCheckbox);
  const isDisabled = form.values.isDisabled;
  const isDisabledButton = settings
    ? form.values.isEnabledRules === settings?.IsEnabledRules &&
      form.values.isDisabled === settings?.IsDisabled
    : true;

  const handleChangeIsDisabled = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isDisabled = e.target.checked;
    form.setFieldValue('isDisabled', isDisabled);

    if (isDisabled) {
      form.setFieldValue('isEnabledRules', true);
    }
  };

  const handleChangeIsEnabledRules = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isDisabled = e.target.checked;
    form.setFieldValue('isEnabledRules', isDisabled);
  };

  const showTable = settings && settings?.Rules?.length !== 0;

  return (
    <div className={classes.container}>
      <div className={classes.title}>{t('ShareLinks.title')}</div>
      {loading && (
        <>
          <Skeleton height={20} radius="sm" width={200} />
          <Skeleton height={20} radius="sm" width={200} />
          <Skeleton height={20} radius="sm" width={200} />
          <Skeleton height={30} mt={1} radius="sm" />
          <Skeleton height={30} mt={1} radius="sm" />
          <Skeleton height={30} mt={1} radius="sm" />
        </>
      )}
      {!loading && (
        <form onSubmit={form.onSubmit(handleSubmit)} className={classes.form}>
          <Checkbox
            size="xs"
            classNames={{ label: classes.checkboxLabel }}
            checked={isDisabled}
            onChange={handleChangeIsDisabled}
            label={t('ShareLinks.isDisabled')}
          />
          {isDisabled && (
            <>
              <Checkbox
                size="xs"
                className={exceptionCheckbox}
                checked={form.values.isEnabledRules}
                onChange={handleChangeIsEnabledRules}
                label={t('ShareLinks.isEnabledRules')}
              />
              <div className={classes.description}>{t('ShareLinks.helper')}</div>
              <PopUpUI
                mobileSize="100%"
                desktopPosition="right-start"
                trigger={RulesSettingsPopUpTrigger}
              >
                {({ isOpen, setIsOpen }) => (
                  <RulesSettings
                    isOpen={isOpen}
                    onSuccess={loadSettings}
                    setIsOpen={setIsOpen}
                    settings={settings}
                  />
                )}
              </PopUpUI>
              {showTable && (
                <RulesTable
                  settings={settings}
                  rules={settings.Rules || []}
                  deleteRule={deleteRule}
                  loadSettings={loadSettings}
                />
              )}
            </>
          )}
          <div className={classes.description}>{t('ShareLinks.description')}</div>
          <Button size="sm" radius={8} disabled={isDisabledButton} mr="sm" type="submit">
            {t('save')}
          </Button>
        </form>
      )}
    </div>
  );
};
