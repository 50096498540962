import React, { FC } from 'react';
import { ActionIcon, Badge } from '@mantine/core';

import { IconX } from '@tabler/icons';
import { themeColors } from '@/theme/colors';

import { useStyles } from './styles';

interface IBadges {
  data: readonly { id: number; name: string }[];
  onRemove?: (id: number) => void;
}

export const Badges: FC<IBadges> = ({ data, onRemove }) => {
  const { classes } = useStyles();

  return (
    <>
      {data.map(({ id, name }) => {
        return (
          <Badge
            className={classes.badge}
            key={id}
            variant="outline"
            rightSection={
              <ActionIcon size={16} variant="transparent" onClick={() => onRemove?.(id)}>
                <IconX size={16} color={themeColors.brandGray[0]} />
              </ActionIcon>
            }
          >
            {name}
          </Badge>
        );
      })}
    </>
  );
};
