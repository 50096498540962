import React from 'react';
import { ActionIcon, Modal, Popover } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';
import { useClickOutside } from '@mantine/hooks';
import { useGetDeviceType } from '@/entities/device';
import { FloatingPosition } from '@mantine/core/lib/components/Floating';
import { MantineSize } from '@mantine/core/lib/core';

export interface IUsePopUpUI {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setMobileSize: React.Dispatch<React.SetStateAction<MantineSize | (string & object) | number>>;
  handleOpenPopUp: () => void;
  handleClosePopUp: () => void;
}

interface IPopUpUIProps {
  withinPortal?: boolean;
  desktopPosition?: FloatingPosition;
  mobileSize?: MantineSize | (string & NonNullable<unknown>) | number;
  children: React.ReactNode | ((props: IUsePopUpUI) => React.ReactNode);
  trigger?: React.ReactNode | ((props: IUsePopUpUI) => React.ReactNode);
}

export const PopUpUI = (props: IPopUpUIProps) => {
  const { isMobile } = useGetDeviceType();
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const handleOpenPopUp = () => setIsOpen(true);
  const handleClosePopUp = () => setIsOpen(false);
  const ref = useClickOutside(handleClosePopUp);
  const { desktopPosition = 'right-end', mobileSize = '90%', withinPortal = false } = props;
  const [innerMobileSize, setMobileSize] = React.useState<MantineSize | (string & any) | number>(
    mobileSize
  );

  const propsToComponents: IUsePopUpUI = {
    setMobileSize,
    isOpen,
    setIsOpen,
    handleOpenPopUp,
    handleClosePopUp
  };

  const children =
    typeof props.children === 'function' ? props.children(propsToComponents) : props.children;
  const trigger =
    typeof props.trigger === 'function' ? props.trigger(propsToComponents) : props.trigger;

  return (
    <>
      <Popover withinPortal={withinPortal} opened={isOpen} position={desktopPosition} shadow="md">
        <Popover.Target>
          <div style={{ display: 'flex' }}>
            {trigger && trigger}
            {!trigger && (
              <ActionIcon onClick={handleOpenPopUp} className="singleAdd">
                <IconPlus color="rgba(51, 154, 240, 1)" />
              </ActionIcon>
            )}
          </div>
        </Popover.Target>

        {!isMobile && (
          <Popover.Dropdown style={{ padding: 0 }} ref={ref as any}>
            {children}
          </Popover.Dropdown>
        )}
      </Popover>
      {isMobile && (
        <Modal.Root size={innerMobileSize} opened={isOpen} centered onClose={handleClosePopUp}>
          <Modal.Overlay />
          <Modal.Content>
            <Modal.Body style={{ padding: 0 }}>{children}</Modal.Body>
          </Modal.Content>
        </Modal.Root>
      )}
    </>
  );
};
