import React, { FC } from 'react';
import { Anchor } from '@mantine/core';

interface IUserProps {
  UserName?: string;
  UserId?: number;
}

export const User: FC<IUserProps> = ({ UserName, UserId }) => {
  return (
    <Anchor fz={14} underline="never" target="_blank" href={`/profile/${UserId}`}>
      {UserName}
    </Anchor>
  );
};
