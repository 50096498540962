import React, { useEffect } from 'react';
import {
  Button,
  LoadingOverlay,
  Box,
  UnstyledButton,
  PasswordInput,
  SimpleGrid,
  TextInput
} from '@mantine/core';
import { useStyles } from '../styles';
import { showNotification } from '@mantine/notifications';
import { useLazyCheckExistsEmailForLinkQuery } from '@/entities/admin-app/email/api';
import { ILinkedEmail } from '@/entities/admin-app/email';
import { Modal } from '@/components/modal';
import { FormErrors, useForm } from '@mantine/form';
import { useTranslation } from 'react-i18next';
import { getValidateErrors } from '@/lib/utils/validation';
import { z } from 'zod';
import { extractErrorCode } from '@/lib/utils/extractFetchErrors';
import { generateErrorMessage } from '@/lib/utils/generateErrorMessage';

interface ILinkEmailModalProps {
  handleAddEmail?: (data: ILinkedEmail) => void;
  opened: boolean;
  handleClose: () => void;
  filialId?: number | null;
  isLoading: boolean;
  isSuccess: boolean;
  editUser: boolean;
}
export const LinkEmailModal = ({
  handleAddEmail,
  opened,
  handleClose,
  filialId,
  isLoading,
  isSuccess,
  editUser
}: ILinkEmailModalProps) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const [checkLinkEmail, { isError, isFetching }] = useLazyCheckExistsEmailForLinkQuery();

  const linkFormSchema = z.object({
    Email: z.string().email({ message: t('emailFormatError') })
    // Password: z.string().min(8, { message: t('passwordMinError') })
  });

  const linkForm = useForm({
    initialValues: {
      Email: '',
      Password: ''
    },
    validateInputOnChange: true,
    validate: (values: any): FormErrors => getValidateErrors(linkFormSchema, values)
  });

  const isDisabled = Object.keys(linkForm.errors).length !== 0 || !linkForm.isDirty();

  const modalClose = () => {
    linkForm.reset();
    handleClose();
  };

  const handleSubmit = async () => {
    linkForm.validate();
    if (!linkForm.isValid()) return;
    const { Email, Password } = linkForm.values;
    try {
      const res = await checkLinkEmail({
        Email: Email,
        CustomerId: filialId
      });

      if ('error' in res) {
        const codeError = extractErrorCode(res.error);
        showNotification({
          title: generateErrorMessage(codeError),
          message: '',
          autoClose: true,
          color: 'red'
        });
      } else {
        if (!editUser) {
          modalClose();
        }
        if (handleAddEmail && res.data) {
          handleAddEmail({
            Id: res.data.Id,
            Password: Password,
            UserId: res.data.UserId,
            Email: Email,
            EmailServer: {
              Id: res?.data?.EmailServer?.Id || 0,
              Name: res?.data?.EmailServer?.Name
            }
          });
        }
      }
    } catch (err: any) {
      console.warn(t('profile.error.createEmail'), err);
    }
  };

  useEffect(() => {
    if (isSuccess && !isLoading) {
      modalClose();
    }
  }, [isSuccess, isLoading]);

  return (
    <Modal opened={opened} onClose={modalClose} title={t('profile.addEmailAccount')} zIndex={1300}>
      <form className={classes.modalRoot}>
        <Box className={classes.modalContainer}>
          <SimpleGrid cols={1} w="100%">
            <TextInput
              className={classes.textFieldInput}
              placeholder={t('profile.email')}
              label={t('login.login')}
              withAsterisk
              {...linkForm.getInputProps('Email')}
            />
            <PasswordInput
              className={classes.textFieldInput}
              placeholder={t('profile.password')}
              label={t('login.password')}
              withAsterisk
              {...linkForm.getInputProps('Password')}
            />
          </SimpleGrid>
        </Box>
        <Box className={classes.modalFooter}>
          <Button size="sm" radius={8} w="49%" disabled={isDisabled} onClick={handleSubmit}>
            {t('profile.add')}
          </Button>
          <UnstyledButton ml={8} className={classes.modalCancelBtn} w="49%" onClick={modalClose}>
            {t('cancel')}
          </UnstyledButton>
        </Box>
        <LoadingOverlay
          visible={(isFetching && !isError) || isLoading}
          overlayProps={{ blur: 2 }}
        />
      </form>
    </Modal>
  );
};
