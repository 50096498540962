export enum AccessType {
  Deny = -4096,
  Read = 0,
  Comment = 1,
  Write = 4,
  Delete = 8,
  Full = 512,
  OnlyUpload = -1024,
  FreeBusy = -64,
  Upload = 2,
  Sharing = 3,
  Save = 16,
  EditRights = 32,
  Lock = 4096
}

export enum AccessRightKind {
  Role = 0,
  User = 1
}

export enum CustomerSettingType {
  None = 0,
  UserIpType = 1,
  AdminIpType = 2,
  SessionDurationType = 3,
  PassEnterCountType = 4,
  LdapType = 5,
  Smtp = 6,
  PasswordSecurity = 7,
  QuotaRules = 8,
  TimeZone = 9,
  ExternalLinkRules = 10
}
