import { useEffect, useState } from 'react';
import { useViewportSize } from '@mantine/hooks';
import { DeviceSize } from '../types';

export const useGetDeviceType = () => {
  const { width } = useViewportSize();
  const [deviceType, setDeviceType] = useState<DeviceSize>(DeviceSize.desktop);

  useEffect(() => {
    if (width > 1200) {
      setDeviceType(DeviceSize.desktop);
    } else if (width <= 1200 && width >= 769) {
      setDeviceType(DeviceSize.tablet);
    } else {
      setDeviceType(DeviceSize.mobile);
    }
  }, [width]);

  const isMobile = deviceType === DeviceSize.mobile;
  const isTablet = deviceType === DeviceSize.tablet;
  const isDesktop = deviceType === DeviceSize.desktop;

  return {
    isMobile,
    isTablet,
    isDesktop
  };
};
