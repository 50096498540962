import React, { useCallback, useMemo } from 'react';
import {
  Combobox,
  ComboboxDropdownTargetProps,
  ComboboxEventsTargetProps,
  Pill,
  PillsInput,
  useCombobox
} from '@mantine/core';
import { usePillAutoCompleteContext } from '@/containers/pages/filials/edit/components/tabs/security/components/share-links/components/PillsInputAutoCompleteWithQuery/usePillAutoCompleteContext';
import {
  DefaultOption,
  IPillAutoCompleteOptionComponent
} from '@/containers/pages/filials/edit/components/tabs/security/components/share-links/components/PillsInputAutoCompleteWithQuery/components/DefaultOption';
import {
  DefaultPill,
  IPillAutoCompletePillComponent
} from '@/containers/pages/filials/edit/components/tabs/security/components/share-links/components/PillsInputAutoCompleteWithQuery/components/DefaultPill';
import { PillsInputProps } from '@mantine/core/lib/components/PillsInput/PillsInput';
import { ComboboxProps } from '@mantine/core/lib/components/Combobox/Combobox';
import { PillGroupProps } from '@mantine/core/lib/components/Pill/PillGroup/PillGroup';
import { PillsInputFieldProps } from '@mantine/core/lib/components/PillsInput/PillsInputField/PillsInputField';
import { ComboboxDropdownProps } from '@mantine/core/lib/components/Combobox/ComboboxDropdown/ComboboxDropdown';
import { ComboboxOptionsProps } from '@mantine/core/lib/components/Combobox/ComboboxOptions/ComboboxOptions';
import { ComboboxEmptyProps } from '@mantine/core/lib/components/Combobox/ComboboxEmpty/ComboboxEmpty';

export interface IPillOption<T = any> {
  label: string;
  value: number;
  entity?: T;
}

interface Props<T extends IPillOption, E> {
  defaultValues?: T[];
  options?: T[];

  onValueChange(items: T[]): void;

  props?: {
    PillsInputProps?: PillsInputProps;
    PillGroupProps?: PillGroupProps;
    PillsInputFieldProps?: PillsInputFieldProps;
    ComboboxEventsTargetProps?: ComboboxEventsTargetProps;
    ComboboxProps?: ComboboxProps;
    ComboboxDropdownTargetProps?: ComboboxDropdownTargetProps;
    ComboboxDropdownProps?: ComboboxDropdownProps;
    ComboboxOptionsProps?: ComboboxOptionsProps;
    ComboboxEmptyProps?: ComboboxEmptyProps;
  };

  renderLabel?(data: T): string;

  loaderData?(search: string): Promise<E[]>;

  mapDataToOptions?(item: E): T;

  RenderOption?<T extends IPillOption>(props: IPillAutoCompleteOptionComponent<T>): JSX.Element;

  RenderPill?<T extends IPillOption>(props: IPillAutoCompletePillComponent<T>): JSX.Element;
}

const defaultRenderLabel = (item: IPillOption) => item.label;

export const PillsInputAutoCompleteWithQuery = <T extends IPillOption, E>(props: Props<T, E>) => {
  const {
    defaultValues = [],
    options: defaultOptions = [],
    onValueChange,
    loaderData,
    mapDataToOptions,
    renderLabel = defaultRenderLabel,
    RenderOption = DefaultOption,
    RenderPill = DefaultPill
  } = props;

  const { options, input, setInput, values, setValues } = usePillAutoCompleteContext<T, E>({
    defaultValues,
    defaultOptions,
    onValueChange,
    loaderData,
    mapDataToOptions
  });

  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
    onDropdownOpen: () => combobox.updateSelectedOptionIndex('active')
  });

  const actualOptions = useMemo(() => options.filter(Boolean), [options]);

  const handleValueSelect = useCallback(
    (name: string) => {
      const val = actualOptions.find((item) => item.value.toString() === name);
      if (!val) return;

      setValues((current) =>
        current.map((i) => i.value).includes(val.value)
          ? current.filter((v) => v.value !== val.value)
          : [...current, val]
      );
    },
    [actualOptions, setValues]
  );

  const handleValueRemove = useCallback(
    (val: IPillOption) => {
      setValues((current) => current.filter((v) => v.value !== val.value));
    },
    [setValues]
  );

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      combobox.updateSelectedOptionIndex();
      setInput(event.currentTarget.value);
    },
    [combobox, setInput]
  );

  const onKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Backspace' && input.length === 0) {
        event.preventDefault();
        handleValueRemove(values[values.length - 1]);
      }
    },
    [input, values, handleValueRemove]
  );

  const onFocus = useCallback(() => combobox.openDropdown(), [combobox]);
  const onBlur = useCallback(() => combobox.closeDropdown(), [combobox]);

  const valuesToRender = useMemo(
    () =>
      values.map((item: T) => (
        <RenderPill
          item={item}
          input={input}
          values={values}
          key={item.value}
          renderLabel={renderLabel}
          handleValueRemove={handleValueRemove}
        />
      )),
    [values, input, renderLabel, handleValueRemove]
  );

  const optionsToRender = useMemo(
    () =>
      actualOptions.map((item, index) => (
        <RenderOption
          key={index}
          options={options}
          input={input}
          setInput={setInput}
          item={item}
          values={values}
          setValues={setValues}
        />
      )),
    [actualOptions, options, input, setInput, values, setValues]
  );

  const hasOptions = useMemo(() => actualOptions.length > 0, [actualOptions]);

  const defaultProps = {
    PillsInputProps: {
      onClick: onFocus
    },
    PillGroupProps: {},
    PillsInputFieldProps: {
      onFocus,
      onBlur,
      value: input,
      placeholder: 'Search values',
      onChange,
      onKeyDown
    },
    ComboboxEventsTargetProps: {},
    ComboboxProps: {
      store: combobox,
      withinPortal: false,
      onOptionSubmit: handleValueSelect
    },
    ComboboxDropdownTargetProps: {},
    ComboboxDropdownProps: {},
    ComboboxOptionsProps: {},
    ComboboxEmptyProps: {
      children: 'No options'
    }
  };

  const {
    PillsInputProps = defaultProps.PillsInputProps,
    PillGroupProps = defaultProps.PillGroupProps,
    PillsInputFieldProps = defaultProps.PillsInputFieldProps,
    ComboboxEventsTargetProps = defaultProps.ComboboxEventsTargetProps,
    ComboboxProps = defaultProps.ComboboxProps,
    ComboboxDropdownTargetProps = defaultProps.ComboboxDropdownTargetProps,
    ComboboxDropdownProps = defaultProps.ComboboxDropdownProps,
    ComboboxOptionsProps = defaultProps.ComboboxOptionsProps,
    ComboboxEmptyProps = defaultProps.ComboboxEmptyProps
  } = props.props || defaultProps;

  return (
    <Combobox {...defaultProps.ComboboxProps} {...ComboboxProps}>
      <Combobox.DropdownTarget
        {...defaultProps.ComboboxDropdownTargetProps}
        {...ComboboxDropdownTargetProps}
      >
        <PillsInput {...defaultProps.PillsInputProps} {...PillsInputProps}>
          <Pill.Group {...defaultProps.PillGroupProps} {...PillGroupProps}>
            {valuesToRender}
            <Combobox.EventsTarget
              {...defaultProps.ComboboxEventsTargetProps}
              {...ComboboxEventsTargetProps}
            >
              <PillsInput.Field {...defaultProps.PillsInputFieldProps} {...PillsInputFieldProps} />
            </Combobox.EventsTarget>
          </Pill.Group>
        </PillsInput>
      </Combobox.DropdownTarget>
      <Combobox.Dropdown {...defaultProps.ComboboxDropdownProps} {...ComboboxDropdownProps}>
        <Combobox.Options {...defaultProps.ComboboxOptionsProps} {...ComboboxOptionsProps}>
          {hasOptions && optionsToRender}
          {!hasOptions && (
            <Combobox.Empty {...defaultProps.ComboboxEmptyProps} {...ComboboxEmptyProps}>
              {ComboboxEmptyProps.children}
            </Combobox.Empty>
          )}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
};

export default React.memo(PillsInputAutoCompleteWithQuery, (prevProps, nextProps) => {
  // Проверяем массив options
  if (prevProps.options !== nextProps.options) return false;

  // Проверяем функции
  if (prevProps.onValueChange !== nextProps.onValueChange) return false;
  if (prevProps.renderLabel !== nextProps.renderLabel) return false;
  if (prevProps.loaderData !== nextProps.loaderData) return false;
  if (prevProps.mapDataToOptions !== nextProps.mapDataToOptions) return false;
  if (prevProps.RenderOption !== nextProps.RenderOption) return false;
  if (prevProps.RenderPill !== nextProps.RenderPill) return false;

  // Проверяем вложенные props
  if (JSON.stringify(prevProps.props) !== JSON.stringify(nextProps.props)) return false;

  // Если все проверки прошли, избегаем перерендеринга
  return true;
});
