import { Badge, Table } from '@mantine/core';
import React from 'react';
import { SettingsRule } from '@/services/customerSettingsApiArea/types';
import { IconUsersGroup } from '@tabler/icons-react';
import classes from './cells.module.css';

interface Props {
  rule: SettingsRule;
}

const renderRoles = (roles: { Name: string }[]): JSX.Element[] => {
  const Icon = (
    <div className={classes.iconWrapper}>
      <IconUsersGroup size={20} color="#A4B0C2" strokeWidth="1" />
    </div>
  );

  return roles.map((role, index) => (
    <Badge className={classes.badge} key={index} variant="outline" leftSection={Icon}>
      {role.Name}
    </Badge>
  ));
};

export const RolesCell = ({ rule }: Props) => {
  const value = renderRoles(rule.Roles);

  return (
    <Table.Td width={300} className={classes.wrapperBadge}>
      {value}
    </Table.Td>
  );
};
