import { RoleServiceType } from './interface';
import { GetRolesByNameParams, GetRolesForUserParams } from '@/services/roleApiArea/types';
import { DEFAULT__LIST__PARAMS, generateParamsForRequest } from '@/services/core';
import axiosInstance from '@/api';
import { IRolesTableData } from '@/entities/admin-app/roles';

type Response<T> = {
  Response: {
    Data: {
      Items: T[];
      TotalCount: number;
    };
    TypeName: number;
  };
  Status: number;
  StatusCode: number;
};

/** Класс фабрика RoleService который хранит в себе API методы для работы с ролями **/
export class RoleService implements RoleServiceType {
  async GetRolesByName(params: GetRolesByNameParams): Promise<IRolesTableData[]> {
    try {
      const paramsToQuery = {
        ...DEFAULT__LIST__PARAMS,
        ...params
      };

      const { data } = await axiosInstance.post<Response<IRolesTableData>>(
        'v2/roles/list',
        paramsToQuery
      );

      return data.Response.Data.Items;
    } catch (e) {
      throw new Error(`Error: ${e}`);
    }
  }

  async GetRolesForUser(params: GetRolesForUserParams): Promise<IRolesTableData[]> {
    try {
      const { data } = await axiosInstance.get<Response<IRolesTableData>>(
        generateParamsForRequest('v2/moduleaccess/roles', params)
      );

      return data.Response.Data.Items;
    } catch (e) {
      throw new Error(`Error: ${e}`);
    }
  }
}
