import { Modal } from '@/components/modal';
import { Box, Flex, Radio, SimpleGrid, Stack, Text, Tooltip } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';
import { TextFieldInput } from '@/components/fields/text-field-input';
import { NumberInput } from '@mantine/core';
import { FormErrors, useForm } from '@mantine/form';
import { SelectQuotas } from '@/containers/pages/user/components/Quotaselect';
import { useAppSelector } from '@/hooks/redux/redux';
import { Button } from '@mantine/core';
import { getValidateErrors } from '@/lib/utils/validation';
import { validateQuotaRelationScheme } from '@/containers/pages/filials/schemes/validate';
import { getEntityTypeFromUrl } from '@/containers/pages/filials/edit/utiils';
import { useEffect, useMemo } from 'react';
import { IQuotaRelationForm } from '@/entities/admin-app/quotas';
import {
  useAddQuotaRelationsMutation,
  useEditQuotaRelationsMutation
} from '@/entities/admin-app/quotas/api';
import { universalNotification } from '@/lib/utils/notification';
import { convertGbToByte } from '@/lib/utils/format';
import { LoadingOverlay } from '@mantine/core';
import { IconInfoCircle } from '@tabler/icons';

interface IAssignQuotaModalModalProps {
  opened: boolean;
  handleClose: () => void;
}

export const AssignQuotaModal = ({ opened, handleClose }: IAssignQuotaModalModalProps) => {
  const { t } = useTranslation();
  const { classes, theme } = useStyles();

  const { filial } = useAppSelector((state) => state.filials);
  const [addQuotaRelation, { isLoading }] = useAddQuotaRelationsMutation();
  const [editQuotaRelation, { isLoading: isLoadingEdit }] = useEditQuotaRelationsMutation();

  // Edit state
  const { selectedQuotaRelation } = useAppSelector((state) => state.quotas);
  const isEditModal = useMemo(() => !!selectedQuotaRelation, [selectedQuotaRelation]);
  // ----------------

  const form = useForm<IQuotaRelationForm>({
    initialValues: {
      CustomerId: null,
      Url: '',
      QuotaId: null,
      QuotaName: '',
      EntityType: null,
      NewQuota: false,
      Quota: null
    },
    validateInputOnChange: true,
    validate: (values: IQuotaRelationForm): FormErrors =>
      getValidateErrors(validateQuotaRelationScheme(t), values)
  });

  useEffect(() => {
    if (!filial) {
      return;
    }
    const formData =
      isEditModal && selectedQuotaRelation
        ? {
            CustomerId: filial.Id,
            QuotaId: selectedQuotaRelation.Quota.Id,
            EntityType: selectedQuotaRelation.Entity.Type,
            NewQuota: false,
            Url: selectedQuotaRelation.Url,
            Quota: {
              Name: '',
              Size: null
            }
          }
        : {
            CustomerId: filial.Id,
            Url: '',
            QuotaId: null,
            EntityType: null,
            NewQuota: false,
            Quota: { Name: '', Size: null }
          };
    form.setValues(formData);
    form.resetDirty(formData);
  }, [filial, selectedQuotaRelation]);

  const closeModal = () => {
    form.reset();
    handleClose();
  };

  const handleSubmit = async (values: IQuotaRelationForm) => {
    const relationQuotaValues = {
      CustomerId: values.CustomerId,
      Url: values.Url,
      QuotaId: values.NewQuota ? null : values.QuotaId,
      EntityType: getEntityTypeFromUrl(values.Url),
      Quota: values.NewQuota
        ? { Name: values.Quota?.Name, Size: convertGbToByte(values.Quota?.Size) }
        : null
    };

    try {
      const res = isEditModal
        ? await editQuotaRelation(relationQuotaValues)
        : await addQuotaRelation(relationQuotaValues);
      if ('error' in res) {
        universalNotification({
          error: res.error,
          isError: true,
          isSuccess: false
        });
      } else {
        universalNotification({
          isSuccess: true,
          isError: false,
          error: null,
          successTitle: isEditModal
            ? t('filials.diskSpace.editQuotaSuccess')
            : t('filials.diskSpace.assignQuotaSuccess')
        });

        closeModal();
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Modal
      opened={opened}
      onClose={closeModal}
      title={isEditModal ? t('filials.diskSpace.editAssign') : t('filials.diskSpace.newAssign')}
      zIndex={1300}
      size="420px"
    >
      <form className={classes.modalRoot} onSubmit={form.onSubmit(handleSubmit)}>
        <Box className={classes.modalContainer}>
          <SimpleGrid cols={1} w="100%" spacing="xs">
            <TextFieldInput
              form={form}
              fieldName="Url"
              label={
                <Flex gap="xs">
                  {t('link')}
                  <Text c="red">*</Text>
                  <Tooltip
                    w="450px"
                    multiline={true}
                    zIndex={2000}
                    label={t('filials.diskSpace.urlTooltip')
                      .split('\n')
                      .map((item: string, index: number) => (
                        <Text key={index} size="sm">
                          {item}
                        </Text>
                      ))}
                  >
                    <Box h={16}>
                      <IconInfoCircle size={16} stroke="2" color={theme.colors.brandGray[0]} />
                    </Box>
                  </Tooltip>
                </Flex>
              }
              placeholder={t('filials.diskSpace.pastUrl')}
              dataAttribute="urlInput"
              disabled={isEditModal}
              isRequired
              withAsterisk={false}
            />

            <Stack gap="xs" mt="5px">
              <Radio
                checked={!form.values.NewQuota}
                onChange={() => form?.setFieldValue('NewQuota', false)}
                className={classes.radio}
                label={t('filials.diskSpace.useExistingQuota')}
                data-cy="useExistingQuotaButton"
              />
              {!form.values.NewQuota ? (
                <SelectQuotas
                  form={form}
                  fieldName="QuotaId"
                  value={form.values.QuotaId}
                  Customer={filial?.Id}
                  label={t('profile.selectDefaultQuota')}
                  className={classes.select}
                  quotaField="QuotaName"
                  QuotaName={form.values.QuotaName}
                />
              ) : null}
              <Radio
                checked={form.values.NewQuota}
                onChange={() => form?.setFieldValue('NewQuota', true)}
                className={classes.radio}
                label={t('filials.diskSpace.createNewQuota')}
                data-cy="createNewQuotaButton"
              />
            </Stack>

            {form.values.NewQuota ? (
              <Stack gap="xs" ml="30px">
                <TextFieldInput
                  form={form}
                  fieldName="Quota.Name"
                  label={t('filials.diskSpace.quotaName')}
                  dataAttribute="quotaName"
                  isRequired
                />
                <NumberInput
                  autoComplete="off"
                  hideControls
                  allowNegative={false}
                  withAsterisk
                  className={classes.numberInput}
                  label={t('filials.diskSpace.quotaSizeInGb')}
                  {...form.getInputProps('Quota.Size')}
                />
              </Stack>
            ) : null}
            <Text fz="sm" c="#667085" mt="xs">
              {t('filials.diskSpace.assignQuotaDescription')}
            </Text>
          </SimpleGrid>
        </Box>
        <Box className={classes.modalFooter}>
          <Button
            type="submit"
            size="sm"
            radius={8}
            fullWidth
            disabled={!form.isValid() || !form.isDirty()}
          >
            {isEditModal ? t('filials.diskSpace.save') : t('filials.diskSpace.assignQuotas')}
          </Button>
        </Box>
        <LoadingOverlay visible={isLoading || isLoadingEdit} overlayProps={{ blur: 2 }} />
      </form>
    </Modal>
  );
};
