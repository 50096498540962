import { ActionIcon, Loader, Table } from '@mantine/core';
import React, { useState } from 'react';
import { IconEdit, IconTrash } from '@tabler/icons-react';
import classes from './cells.module.css';
import { RulesSettings } from '@/containers/pages/filials/edit/components/tabs/security/components/share-links/components/RulesSettings';
import { PopUpUI } from '@components/UI/PopUp';
import { ExternalLinksSettings, SettingsRule } from '@/services/customerSettingsApiArea/types';

interface Props {
  settings: ExternalLinksSettings | undefined;
  rule: SettingsRule;

  loadSettings(): Promise<void>;

  deleteRule(id: number): Promise<void>;
}

export const ActionCell = (props: Props) => {
  const { settings, loadSettings, rule, deleteRule } = props;
  const [isLoading, setIsLoading] = useState(false);

  const handleDeleteRule = () => {
    setIsLoading(true);
    deleteRule(rule.Id).finally(() => setIsLoading(false));
  };

  const trashIcon = isLoading ? (
    <Loader color="grey" size={24} />
  ) : (
    <IconTrash size={24} stroke={1.5} />
  );

  return (
    <Table.Td width={60} align="right">
      <div className={classes.actions}>
        <PopUpUI
          mobileSize="100%"
          desktopPosition="top-end"
          trigger={({ setIsOpen }) => (
            <ActionIcon onClick={() => setIsOpen(true)}>
              <IconEdit size={24} stroke={1.5} />
            </ActionIcon>
          )}
        >
          {({ isOpen, setIsOpen }) => (
            <RulesSettings
              isOpen={isOpen}
              rule={rule}
              onSuccess={loadSettings}
              setIsOpen={setIsOpen}
              settings={settings}
            />
          )}
        </PopUpUI>
        <ActionIcon onClick={handleDeleteRule} disabled={isLoading}>
          {trashIcon}
        </ActionIcon>
      </div>
    </Table.Td>
  );
};
