import { FC } from 'react';
import { Checkbox, Flex, Text } from '@mantine/core';
import { IRemoveUser } from '@/entities/admin-app/users';
import { TransferData } from '../transfer-data';
import { useTranslation } from 'react-i18next';
import OverflowTip from '@/components/OverflowTip';
import { useStyles } from '../styles';
import { UserAvatar } from '@/components/user-avatar';

interface IListItem {
  isSelectedMultiple?: boolean;
  value?: IRemoveUser;
  onChange: (user: IRemoveUser) => void;
  SkipUsersIds?: number[];
}

export const ListItem: FC<IListItem> = ({ isSelectedMultiple, value, onChange, SkipUsersIds }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  if (!value) {
    return null;
  }

  const { isChecked, email, FirstName, SurName, id, isNotAcceptable, Avatar } = value;

  const handleCheckChange = () => {
    onChange({ ...value, isChecked: !value?.isChecked });
  };

  return (
    <Flex py={12} gap="6px" className={classes.itemContainer}>
      <Flex align="center" direction="row" gap="xs" className={classes.select}>
        {isSelectedMultiple && (
          <Checkbox
            disabled={isNotAcceptable}
            checked={isNotAcceptable ? false : isChecked}
            size="xs"
            onChange={handleCheckChange}
          />
        )}
        <UserAvatar user={value} src={Avatar} userId={id} size={40} />
        <Flex direction="column" className={classes.userInfo}>
          {SurName || FirstName ? (
            <OverflowTip fz="sm" fw="bold">
              {`${SurName || ''} ${FirstName || ''}`}
            </OverflowTip>
          ) : null}
          {email ? (
            <OverflowTip fz="xs" color="brandGray.0">
              {email}
            </OverflowTip>
          ) : null}
        </Flex>
      </Flex>
      <Flex className={classes.transferInfo}>
        {isNotAcceptable ? (
          <Text fz="xs" color="brandGray.0">
            {t('users.noRightsToDelete')}
          </Text>
        ) : (
          <TransferData
            userId={value.id}
            onChange={(transferUserId) => onChange({ ...value, transferUserId })}
            SkipUsersIds={SkipUsersIds}
          />
        )}
      </Flex>
    </Flex>
  );
};
