import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDebounce } from '@hooks/useDebounce';
import { IPillOption } from '@/containers/pages/filials/edit/components/tabs/security/components/share-links/components/PillsInputAutoCompleteWithQuery/index';

type Return<T extends IPillOption> = {
  options: T[];
  input: string;
  setInput: React.Dispatch<React.SetStateAction<string>>;
  values: T[];
  setValues: React.Dispatch<React.SetStateAction<T[]>>;
  isLoading: boolean;
};

interface Args<T extends IPillOption, E> {
  defaultValues: T[];
  defaultOptions: T[];

  onValueChange(items: T[]): void;

  loaderData?(search: string): Promise<E[]>;

  mapDataToOptions?(item: E): T;
}

export const usePillAutoCompleteContext = <T extends IPillOption, E>(
  args: Args<T, E>
): Return<T> => {
  const { loaderData, mapDataToOptions, defaultValues, defaultOptions, onValueChange } = args;

  const [input, setInput] = useState<string>('');
  const debounced = useDebounce(input, 500);
  const [options, setListData] = useState<T[]>([...defaultOptions]);
  const [values, setValues] = useState<T[]>([...defaultValues]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const stopLoading = useCallback(() => setIsLoading(false), []);
  const startLoading = useCallback(() => setIsLoading(true), []);

  useEffect(() => {
    if (!loaderData || !mapDataToOptions) return;
    startLoading();

    loaderData(debounced)
      .then((res) => setListData(res.map(mapDataToOptions)))
      .finally(stopLoading);
  }, [debounced, loaderData, mapDataToOptions]);

  useEffect(() => {
    onValueChange(values);
  }, [values, onValueChange]);

  return useMemo(
    () => ({
      options,
      input,
      values,
      isLoading,
      setValues,
      setInput
    }),
    [options, input, values, isLoading]
  );
};
