import { FormErrors, useForm, UseFormReturnType } from '@mantine/form';
import { z } from 'zod';
import { getValidateErrors } from '@/lib/utils/validation';
import { useEffect, useState } from 'react';
import { customerSettingsApiArea } from '@/services/customerSettingsApiArea';
import { CustomerSettingType } from '@/services/enums';
import { ExternalLinksSettings } from '@/services/customerSettingsApiArea/types';

const schema = z.object({
  isDisabled: z.boolean(),
  isEnabledRules: z.boolean()
});

export type TFormValuesShareLinks = z.infer<typeof schema>;
type Return = [
  UseFormReturnType<TFormValuesShareLinks>,
  () => void,
  ExternalLinksSettings | undefined,
  boolean,
  () => Promise<void>,
  (id: number) => Promise<void>
];

export const useShareLinks = (CustomerId?: number): Return => {
  const schema = z.object({});
  const [settings, setSettings] = useState<ExternalLinksSettings | undefined>(undefined);
  const [loading, setLoading] = useState(false);

  const form = useForm<TFormValuesShareLinks>({
    validateInputOnChange: true,
    initialValues: {
      isDisabled: false,
      isEnabledRules: false
    },
    validate: (values): FormErrors => getValidateErrors(schema, values)
  });

  const loadSettings = async () => {
    const params = {
      id: CustomerId,
      extended: true
    };

    const { Response } = await customerSettingsApiArea().GetCustomer(params);
    const settings = Response.Data.Settings;

    if (!settings) return;

    setSettings(settings.ExternalLinksSettings);

    if (!settings.ExternalLinksSettings) return;

    const values = {
      isDisabled: settings.ExternalLinksSettings?.IsDisabled,
      isEnabledRules: settings.ExternalLinksSettings?.IsEnabledRules
    };
    form.setValues(values);
  };

  const deleteRule = async (id: number) => {
    if (!CustomerId || !settings) return;

    const params = {
      CustomerId,
      Type: CustomerSettingType.ExternalLinkRules,
      Settings: {
        ExternalLinksSettings: {
          IsDisabled: true,
          IsEnabledRules: settings.IsEnabledRules,
          Rules: settings.Rules?.filter((rule) => rule.Id !== id) || []
        }
      }
    };

    await customerSettingsApiArea().SaveCustomerSettings(params).then(loadSettings);
  };

  useEffect(() => {
    if (!CustomerId) return;
    setLoading(true);

    loadSettings().finally(() => setLoading(false));
  }, [CustomerId]);

  const handleSubmit = () => {
    if (!CustomerId) return;
    const params = {
      CustomerId,
      Type: CustomerSettingType.ExternalLinkRules,
      Settings: {
        ExternalLinksSettings: {
          IsDisabled: form.values.isDisabled,
          IsEnabledRules: form.values.isEnabledRules,
          Rules: settings?.Rules || []
        }
      }
    };
    customerSettingsApiArea().SaveCustomerSettings(params).then(loadSettings);
  };

  return [form, handleSubmit, settings, loading, loadSettings, deleteRule];
};
