import { FC, useEffect } from 'react';
import { Box, Button, NumberInput, SimpleGrid, UnstyledButton } from '@mantine/core';
import { FormErrors, useForm } from '@mantine/form';
import { z } from 'zod';
import { TextFieldInput } from '@components/fields/text-field-input';
import { getValidateErrors } from '@/lib/utils/validation';
import { IQuota } from '@/entities/admin-app/quotas';
import { useCreateQuotaMutation, useUpdateQuotaMutation } from '@/entities/admin-app/quotas/api';
import { convertGbToByte, convertByteToGb } from '@/lib/utils/format';
import { useTranslation } from 'react-i18next';
import { useStyles } from '../../../../../styles';
import { extractErrorCode } from '@/lib/utils/extractFetchErrors';
import { showNotification } from '@mantine/notifications';
import { generateErrorMessage } from '@/lib/utils/generateErrorMessage';

interface ISelectGroupModalProps {
  onClose: () => void;
  quota?: IQuota | null;
  CustomerId?: number;
}

interface IQuotaForm {
  name: string;
  size: number;
}

export const UsersQuotaModal: FC<ISelectGroupModalProps> = ({ onClose, quota, CustomerId }) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const [createQuota] = useCreateQuotaMutation();
  const [updateQuota] = useUpdateQuotaMutation();

  const form = useForm<IQuotaForm>({
    validateInputOnChange: true,
    validate: (values: IQuotaForm): FormErrors => getValidateErrors(validateScheme, values)
  });

  const validateScheme = z.object({
    name: z
      .string()
      .trim()
      .min(1, { message: t('quota.errors.required') })
      .max(64, { message: t('validation.maxLengthSymbolsWithCount.symbols', { count: 64 }) }),
    size: z
      .number({
        required_error: t('filials.diskSpace.errors.size'),
        invalid_type_error: t('filials.diskSpace.errors.type')
      })
      .max(1000, { message: t('filials.diskSpace.errors.max') })
      .positive({ message: t('filials.diskSpace.errors.positive') })
      .safe()
  });

  const handleSubmit = async (formValues: IQuotaForm) => {
    try {
      let res;
      if (quota?.id) {
        res = await updateQuota({
          Id: quota.id,
          Name: formValues?.name?.trim(),
          Size: convertGbToByte(formValues.size),
          CustomerId: CustomerId
        });
      } else {
        res = await createQuota({
          Name: formValues?.name?.trim(),
          Size: convertGbToByte(formValues.size),
          CustomerId: CustomerId
        });
      }

      if ('error' in res) {
        const codeError = extractErrorCode(res.error);
        showNotification({
          title: generateErrorMessage(codeError),
          message: '',
          autoClose: true,
          color: 'red',
          style: { zIndex: 1003 }
        });
      } else {
        showNotification({
          title: t('saved'),
          message: '',
          autoClose: true,
          color: 'green',
          style: { zIndex: 1003 }
        });
        form.reset();
        onClose();
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (quota) {
      const formValue = {
        name: quota?.name || '',
        size: convertByteToGb(quota?.size ?? 0)
      };
      form.setValues(formValue);
      form.resetDirty(formValue);
    }
  }, [quota]);

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Box px="md" py="sm">
        <SimpleGrid cols={1}>
          <TextFieldInput form={form} fieldName="name" label={t('filials.diskSpace.quotaName')} />
          <NumberInput
            autoComplete="off"
            hideControls
            withAsterisk
            clampBehavior="strict"
            min={1}
            max={10000}
            className={classes.textFieldInput}
            label={t('filials.diskSpace.quotaSizeInGb')}
            allowNegative={false}
            {...form.getInputProps('size')}
          />
        </SimpleGrid>
      </Box>

      <Box className={classes.modalFooter}>
        <Button
          type="submit"
          size="sm"
          radius={8}
          w="49%"
          disabled={Object.keys(form.errors).length !== 0 || !form.isDirty()}
        >
          {quota ? t('save') : t('create')}
        </Button>

        <UnstyledButton
          type="button"
          ml={8}
          className={classes.modalCancelBtn}
          w="49%"
          onClick={onClose}
        >
          {t('cancel')}
        </UnstyledButton>
      </Box>
    </form>
  );
};
