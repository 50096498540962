import { FormErrors, useForm, UseFormReturnType } from '@mantine/form';
import { z } from 'zod';
import { getValidateErrors } from '@/lib/utils/validation';
import { customerSettingsApiArea } from '@/services/customerSettingsApiArea';
import { CustomerSettingType } from '@/services/enums';
import { ExternalLinksSettings, SettingsRule } from '@/services/customerSettingsApiArea/types';
import { IRolesTableData } from '@/entities/admin-app/roles';
import { t } from 'i18next';
import { useState } from 'react';

type Return = [UseFormReturnType<any>, boolean, () => void, () => void];

const schema = z.object({
  roles: z.array(z.any()).min(1, { message: t('ShareLinks.RulesPopUp.roleError') }),
  passwordRequired: z.boolean(),
  minPasswordLength: z.number().min(6).optional(),
  includeLowercase: z.boolean(),
  includeUppercase: z.boolean(),
  includeNumber: z.boolean(),
  limitExpiration: z.boolean(),
  expirationTime: z.number().optional(),
  folderTypeLimited: z.boolean(),
  folderTypes: z.array(z.string()).optional()
});
type FormValues = z.infer<typeof schema>;
const defaultValues: FormValues = {
  roles: [],
  passwordRequired: false,
  minPasswordLength: 8,
  includeLowercase: true,
  includeUppercase: true,
  includeNumber: true,
  limitExpiration: false,
  expirationTime: 180,
  folderTypeLimited: false,
  folderTypes: ['1', '2', '4']
};

interface Args {
  onClose: () => void;
  onSuccess: () => Promise<void>;
  customerId?: number;
  settings?: ExternalLinksSettings;
  rule?: SettingsRule;
}

export const useRulesSettings = (args: Args): Return => {
  const { onClose, onSuccess, customerId, settings, rule } = args;
  const [isLoading, setIsLoading] = useState(false);

  const Rules = settings?.Rules ? [...settings.Rules] : [];

  const valuesToForm: FormValues = rule
    ? {
        roles: rule.Roles ?? [],
        passwordRequired: !!rule.Password,
        minPasswordLength: rule.Password?.MinLength ?? defaultValues.minPasswordLength,
        includeLowercase: rule.Password?.IsLow ?? defaultValues.includeLowercase,
        includeUppercase: rule.Password?.IsHigh ?? defaultValues.includeUppercase,
        includeNumber: rule.Password?.IsDigit ?? defaultValues.includeNumber,
        limitExpiration: !!rule.MaxActivePeriod,
        expirationTime: rule.MaxActivePeriod ?? defaultValues.expirationTime,
        folderTypeLimited: !!rule.ExternalLinkRuleDirectory,
        folderTypes:
          rule.ExternalLinkRuleDirectory?.map((i) => i.toString()) ?? defaultValues.folderTypes
      }
    : defaultValues;

  const form = useForm<FormValues>({
    validateInputOnChange: false,
    initialValues: {
      ...valuesToForm
    },
    validate: (values): FormErrors => getValidateErrors(schema, values)
  });

  const getId = () => {
    if (rule) return rule.Id;

    if (Rules.length === 0) return 0;

    return Rules[Rules.length - 1].Id + 1;
  };

  const reset = () => {
    if (!customerId) return;
    setIsLoading(true);

    const temp = {
      IsDisabled: true,
      IsEnabledRules: false,
      ...settings
    };
    customerSettingsApiArea()
      .SaveCustomerSettings({
        CustomerId: customerId,
        Type: CustomerSettingType.ExternalLinkRules,
        Settings: {
          ExternalLinksSettings: {
            ...temp,
            Rules: []
          }
        }
      })
      .then(onClose)
      .then(onSuccess)
      .finally(() => setIsLoading(false));
  };

  const handleSubmit = () => {
    const isValid = form.validate();

    if (!customerId || isValid.hasErrors) return;
    setIsLoading(true);
    const temp = {
      IsEnabledRules: false,
      ...settings,
      IsDisabled: true
    };

    const Id = getId();

    const newRule: SettingsRule = {
      Id,
      Roles: form.values.roles as IRolesTableData[],
      Password: form.values.passwordRequired
        ? {
            MinLength: form.values.minPasswordLength || 8,
            IsDigit: form.values.includeNumber,
            IsLow: form.values.includeLowercase,
            IsHigh: form.values.includeUppercase
          }
        : null,
      MaxActivePeriod: form.values.limitExpiration ? form.values.expirationTime || 120 : null,
      ExternalLinkRuleDirectory: form.values.folderTypeLimited
        ? (form.values.folderTypes || []).map((i) => +i)
        : null
    };

    const updatedRules = Rules.filter((r) => r.Id !== rule?.Id);
    updatedRules.push(newRule);

    customerSettingsApiArea()
      .SaveCustomerSettings({
        CustomerId: customerId,
        Type: CustomerSettingType.ExternalLinkRules,
        Settings: {
          ExternalLinksSettings: {
            ...temp,
            Rules: updatedRules
          }
        }
      })
      .then(onClose)
      .then(onSuccess)
      .finally(() => setIsLoading(false));
  };

  return [form, isLoading, handleSubmit, reset];
};
